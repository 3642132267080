/*
 * Copyright 2023 General Code
 */

.QCX .definition .term {
  display: inline;
  font-weight: inherit;
}


.QCX .definition .deftext {
  display: inline;
  margin-left: 0;
}
